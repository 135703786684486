$ff-logo: #16ab9e;
$ff-bg: #222;
$ff-hover: #555;
$ff-text: #ddd;
$ff-border: #aaa;

// backgrounds
$body-bg: $ff-bg;
$card-bg: $ff-bg;
$modal-content-bg: $ff-bg;
$list-group-bg: $ff-bg;
$navbar-dark-color: $ff-bg;
$dropdown-bg: $ff-bg;
$input-bg: $ff-bg;
$input-disabled-bg: $ff-bg;

$dropdown-link-hover-bg: $ff-hover;
$list-group-hover-bg: $ff-hover;

// outlines
$card-border-color: $ff-border;
$table-border-color: $ff-border;
$nav-divider-color: $ff-border;
$list-group-border-color: $ff-border;
$border-color: $ff-border;

// text
$nav-tabs-link-active-color: $ff-logo;
$body-color: $ff-text;
$dropdown-link-color: $ff-text;
$dropdown-link-hover-color: $ff-text;
$navbar-dark-color: $ff-text;
$list-group-color: $ff-text;
$list-group-action-hover-color: $ff-text;
$input-color: $ff-text;

$enable-shadows: true;

$theme-colors: (
    "primary": #16ab9e
);

// bootstrap
@import "~bootstrap/scss/bootstrap";
